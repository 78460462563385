import * as React from 'react';
import { Fragment } from 'react';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import TelegramIcon from '@mui/icons-material/Telegram';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import GradingIcon from '@mui/icons-material/Grading';
import RuleIcon from '@mui/icons-material/Rule';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import TimelineIcon from '@mui/icons-material/Timeline';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import BarChartIcon from '@mui/icons-material/BarChart';
import ApartmentIcon from '@mui/icons-material/Apartment';
import SportsBaseballIcon from '@mui/icons-material/SportsBaseball';
import DiamondIcon from '@mui/icons-material/Diamond';
import CandlestickChartIcon from '@mui/icons-material/CandlestickChart';

export default function Online() {
  return (
    
    <Fragment>
          <List>
            <Link  to='/' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <HomeIcon />
                  </ListItemIcon>
                  <ListItemText  primary={`Home`} />
                  </ListItemButton>
              </ListItem>
            </Link>

            <Link  to='/wallet' component={RouterLink} underline="none" color="inherit">
                <ListItem  disablePadding>
                    <ListItemButton>
                    <ListItemIcon>
                    <AccountBalanceWalletIcon />
                    </ListItemIcon>
                    <ListItemText  primary={`Wallet`} />
                    </ListItemButton>
                </ListItem>
              </Link>


            

            <Link  to='/referrals' component={RouterLink} underline="none" color="inherit">
                <ListItem  disablePadding>
                    <ListItemButton>
                    <ListItemIcon>
                    <Diversity3Icon />
                    </ListItemIcon>
                    <ListItemText  primary={`Referral`} />
                    </ListItemButton>
                </ListItem>
              </Link>

              <Link  to='/plans' component={RouterLink} underline="none" color="inherit">
                <ListItem  disablePadding>
                    <ListItemButton>
                    <ListItemIcon>
                    <CandlestickChartIcon />
                    </ListItemIcon>
                    <ListItemText  primary={`Trade Now`} />
                    </ListItemButton>
                </ListItem>
              </Link>

              {/* <Link  to='/gold-plan' component={RouterLink} underline="none" color="inherit">
                <ListItem  disablePadding>
                    <ListItemButton>
                    <ListItemIcon>
                    <DiamondIcon />
                    </ListItemIcon>
                    <ListItemText  primary={`INVEST IN GOLD`} />
                    </ListItemButton>
                </ListItem>
              </Link>

              <Link  to='/realestate-plan' component={RouterLink} underline="none" color="inherit">
                <ListItem  disablePadding>
                    <ListItemButton>
                    <ListItemIcon>
                    <ApartmentIcon />
                    </ListItemIcon>
                    <ListItemText  primary={`INVEST IN REAL ESTATE`} />
                    </ListItemButton>
                </ListItem>
              </Link>

              <Link  to='/sports-plan' component={RouterLink} underline="none" color="inherit">
                <ListItem  disablePadding>
                    <ListItemButton>
                    <ListItemIcon>
                    <SportsBaseballIcon />
                    </ListItemIcon>
                    <ListItemText  primary={`INVEST IN SPORTS`} />
                    </ListItemButton>
                </ListItem>
              </Link> */}

              <Link  to='/investments' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <AccountBalanceIcon />
                  </ListItemIcon>
                  <ListItemText  primary={`Investments`} />
                  </ListItemButton>
              </ListItem>
            </Link>


              <Link  to='/withdrawals' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <MoneyOffIcon />
                  </ListItemIcon>
                  <ListItemText  primary={`Withdrawals`} />
                  </ListItemButton>
              </ListItem>
            </Link>

            <Link  to='/transactions' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <TimelineIcon />
                  </ListItemIcon>
                  <ListItemText  primary={`Transactions`} />
                  </ListItemButton>
              </ListItem>
            </Link>

            <Link  to='/cards' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <CreditCardIcon />
                  </ListItemIcon>
                  <ListItemText  primary={`Cards`} />
                  </ListItemButton>
              </ListItem>
            </Link>

            <Link to='/shareholders' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <CoPresentIcon />
                  </ListItemIcon>
                  <ListItemText  primary={`Shareholders`} />
                  </ListItemButton>
              </ListItem>
            </Link>

            <Link  to='/loans' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <RuleIcon />
                  </ListItemIcon>
                  <ListItemText  primary={`Loans`} />
                  </ListItemButton>
              </ListItem>
            </Link>
                     
          </List>
          <List>
            
            <Divider/>
            {/* <Link  to='https://tawk.to/chat/642872214247f20fefe94604/1gsuvrltc'  component={RouterLink} underline="none" color="inherit">
            <ListItem  disablePadding>
                <ListItemButton>
                <ListItemIcon>
                <HelpCenterIcon/>
                </ListItemIcon>
                <ListItemText  primary={`Support`} />
                </ListItemButton>
            </ListItem>
          </Link> */}

          <Link  href='https://t.me/+AVw7zE2A1oRkNGJl' underline="none" color="inherit">
            <ListItem  disablePadding>
                <ListItemButton>
                <ListItemIcon>
                <TelegramIcon/>
                </ListItemIcon>
                <ListItemText  primary={`Telegram`} />
                </ListItemButton>
            </ListItem>
          </Link>

          {/* <Link  to='/profile' component={RouterLink} underline="none" color="inherit">
            <ListItem  disablePadding>
                <ListItemButton>
                <ListItemIcon>
                <EmailIcon/>
                </ListItemIcon>
                <ListItemText  primary={`Email Contact`} />
                </ListItemButton>
            </ListItem>
          </Link> */}

          <Link  to='/profile' component={RouterLink} underline="none" color="inherit">
            <ListItem  disablePadding>
                <ListItemButton>
                <ListItemIcon>
                <SettingsIcon/>
                </ListItemIcon>
                <ListItemText  primary={`Settings`} />
                </ListItemButton>
            </ListItem>
          </Link>
    </List>
      
    </Fragment>
  );
}