import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    plans: JSON.parse(localStorage.getItem('plans')) || [],
    realestatePlans: JSON.parse(localStorage.getItem('realestatePlans')) || [],
    goldPlans: JSON.parse(localStorage.getItem('goldPlans')) || [],
    sportsPlans: JSON.parse(localStorage.getItem('sportsPlans')) || [],
}

export const plansSlice = createSlice({
    name: 'plans',
    initialState,
    reducers: {
        setPlans: (state, action) =>{
            state.plans = action.payload;  
            localStorage.setItem('plans', JSON.stringify(state.plans));
        }    
        ,
        setRealestatePlans: (state, action) =>{
            state.realestatePlans = action.payload;  
            localStorage.setItem('plans', JSON.stringify(state.realestatePlans));
        },

        setGoldPlans: (state, action) =>{
            state.goldPlans = action.payload;  
            localStorage.setItem('plans', JSON.stringify(state.goldPlans));
        } 
        ,
        setSportsPlans: (state, action) =>{
            state.sportsPlans = action.payload;  
            localStorage.setItem('plans', JSON.stringify(state.sportsPlans));
        } 
    }
})

export const {setPlans, setRealestatePlans, setGoldPlans, setSportsPlans} = plansSlice.actions;
export default plansSlice.reducer;