import React from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { CardActions } from '@mui/material';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import FolderIcon from '@mui/icons-material/Folder';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { useSelector } from 'react-redux';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import BarChartIcon from '@mui/icons-material/BarChart';
import Diversity3Icon from '@mui/icons-material/Diversity3';

const WalletCards = () => {

    const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);
    
    const fundingBal = useSelector((state) => state.wallet.fundingBalance);
    const subBal = useSelector((state) => state.wallet.subscriptionBalance);
    const refBal = useSelector((state) => state.wallet.referralBalance);
    const mainBal = useSelector((state) => state.wallet.withdrawableBalance);


  return (
    <div>
      <Grid container px={2} mt={4}  mb={4} rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} md={3}>
        <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
        <Link  to={`/product`} component={RouterLink} underline="none" color="inherit">
            <CardMedia sx={{display: 'flex', justifyContent: 'center', paddingY: '12px'}}>
            <Avatar sx={{ bgcolor: '#d7251e' }}>
                <AccountBalanceWalletIcon sx={{ color: '#ffffff' }}/>
            </Avatar>
            </CardMedia>
            <CardContent >
            
            <Typography sx={{ fontWeight: 'bold', fontSize: 16, textTransform: 'capitalize'  }} gutterBottom variant="subtitle2" component="div">
                {formatPrice(fundingBal)}
            </Typography>
            <Typography my={0} variant="body2" color="text.secondary">
                Funding Wallet
            </Typography>
            
            </CardContent>
          </Link>    
        </Card>
    </Grid>

    <Grid item xs={6} md={3}>
        <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
        <Link  to={`/product`} component={RouterLink} underline="none" color="inherit">
            <CardMedia sx={{display: 'flex', justifyContent: 'center', paddingY: '12px'}}>
            <Avatar sx={{ bgcolor: '#d7251e' }}>
                <BarChartIcon sx={{ color: '#ffffff' }}/>
            </Avatar>
            </CardMedia>
            <CardContent >
            
            <Typography sx={{ fontWeight: 'bold', fontSize: 16, textTransform: 'capitalize'  }} gutterBottom variant="subtitle2" component="div">
                {formatPrice(subBal)}
            </Typography>
            <Typography my={0} variant="body2" color="text.secondary">
                Investment Wallet
            </Typography>
            
            </CardContent>
          </Link>    
        </Card>
    </Grid>

    <Grid item xs={6} md={3}>
        <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
        <Link  to={`/product`} component={RouterLink} underline="none" color="inherit">
            <CardMedia sx={{display: 'flex', justifyContent: 'center', paddingY: '12px'}}>
            <Avatar sx={{ bgcolor: '#d7251e' }}>
                <MonetizationOnIcon sx={{ color: '#ffffff' }}/>
            </Avatar>
            </CardMedia>
            <CardContent >
            
            <Typography sx={{ fontWeight: 'bold', fontSize: 16, textTransform: 'capitalize'  }} gutterBottom variant="subtitle2" component="div">
                {formatPrice(mainBal)}
            </Typography>
            <Typography my={0} variant="body2" color="text.secondary">
                Withdrawable Balance
            </Typography>
            
            </CardContent>
          </Link>    
        </Card>
    </Grid>

    <Grid item xs={6} md={3}>
        <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
        <Link  to={`/product`} component={RouterLink} underline="none" color="inherit">
            <CardMedia sx={{display: 'flex', justifyContent: 'center', paddingY: '12px'}}>
            <Avatar sx={{ bgcolor: '#d7251e' }}>
                <Diversity3Icon sx={{ color: '#ffffff' }}/>
            </Avatar>
            </CardMedia>
            <CardContent >
            
            <Typography sx={{ fontWeight: 'bold', fontSize: 16, textTransform: 'capitalize'  }} gutterBottom variant="subtitle2" component="div">
                {formatPrice(refBal)}
            </Typography>
            <Typography my={0} variant="body2" color="text.secondary">
                Referral Wallet
            </Typography>
            
            </CardContent>
          </Link>    
        </Card>
    </Grid>

    

    
    
</Grid>
    </div>
  )
}

export default WalletCards
